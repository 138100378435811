$green-tria: #018c2f;
$white: #ffffff;
$gray: #999999;
$light-gray-bg: #f2f3f5;
$gray-bg-header: #333;
$black: #000000;
$orange: #fd6802;
$gray-bg-toggler: #393939;
$green-bg-card: #06b942;
$green-bg-card2: #2fbc5e;
$green-shadow-thumb: #01a537;
$black-shadow-track: #0d0d0d;
