@import "../../colors.scss";

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.description-footer {
  text-align: left;
}

.title-tria {
  color: $green-tria;
}

.title-footer {
  font-family: Future Tense;
  margin-bottom: 30px;
  text-align: left;
  font-size: 25px;
}

.footer {
  background-color: $gray-bg-toggler;
  color: $white;
  font-family: "Raleway", sans-serif;
  text-align: justify;
  padding: 40px 0 40px;
  line-height: 1.8;
}

.title-horario-footer {
  font-size: 21px;
}

.horarios-footer {
  position: relative;
  display: block;
  margin-bottom: 30px;
  text-align: center;
  padding-top: 25px;
}

.container-instagram a {
  color: $white;
}

.container-instagram a:hover {
  color: $green-tria;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.footer p {
  margin: 0 0 10px;
}

.container-instagram i {
  padding: 0px 5px;
}

.clearfix {
  text-align: center;
}

/*mobile*/
@media only screen and (min-width: 320px) {
  .footer {
    font-size: 12px;
  }
}
/*Table*/
@media only screen and (min-width: 768px) {
  .footer {
    font-size: 12px;
  }
}

/*desktop*/
@media only screen and (min-width: 1024px) {
  .footer {
    font-size: 15px;
  }
}
